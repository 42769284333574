<template>
  <div class="minePage">
    <div class="mineMain">
      <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
        <div class="contentBox">
          <div class="userInfoBox">
            <div @click="toEditInfo" :to="`/userHomepage?uid=${userInfo.uid || 0}`" class="homePagebtn"></div>
            <div class="avatarBox" @click="jumpPage(`/userHomepage?uid=${userInfo.uid || 0}`)">
              <!-- <router-link
              tag="div"
              :to="`/userHomepage?uid=${userInfo.uid || 0}`"
              class="homePagebtn"
            ></router-link>
            <div class="avatarBox" > -->
              <!-- <div class="avatarBorder"></div> -->
              <ImgDecypt class="avatarBorder" :src="userInfo.portraitFrame" :key="userInfo.portraitFrame"
                v-if="userInfo.portraitFrame" />
              <ImgDecypt class="avatarImg" :src="userInfo.portrait" :key="userInfo.portrait" round />
            </div>
            <div class="userName">
              {{ userInfo.name }}
              <svg-icon class="vipSvg" iconClass="vip" v-if="userInfo.isVip && checkTime(userInfo.vipExpireDate)" />
            </div>
            <div class="userInfo">
              <div class="userId">妖精号ID {{ userInfo.uid }}</div>
              <div class="smallVerticalLine"></div>
              <div>{{ userInfo.age || 0 }}岁</div>
              <div class="smallVerticalLine" v-if="userInfo.region"></div>
              <div class="city">{{ userInfo.region }}</div>
              <div class="smallVerticalLine" v-if="userInfo.gender"></div>
              <svg-icon class="sexIcon" iconClass="maleIcon" v-if="userInfo.gender == 'male'" />
              <svg-icon class="sexIcon" iconClass="womanIcon" v-if="userInfo.gender == 'female'" />
            </div>
            <div class="vipBox" v-if="userInfo.isVip" @click="jumpPage('/rechargePage?t=vip')">
              <svg-icon class="vipIcon" iconClass="vipImg" />
              <div class="vipInfo">
                <div class="vipTitle"></div>
                <div class="vipDate">{{ handleVipText() }}</div>
              </div>
              <div class="upLevel"></div>
            </div>
            <div v-else-if="activityImg" @click="jumpPage('/rechargePage?t=vip')">
              <ImgDecypt class="vipImage" :src="activityImg" :key="activityImg" />
            </div>
          </div>
          <div class="signBox" @click="sign">
            <svg-icon class="signIcon" iconClass="signIcon" />
            <div class="signTitle">
              <div class="signTitleImg"></div>
            </div>
            <div class="signBoxBtn">立即签到</div>
          </div>
          <div class="assetsBox">
            <div class="assetsItem" @click="toRecharge">
              <svg-icon class="goldSvg" iconClass="goldSvg" />
              <div class="assetsInfo">
                <div class="assetsTitle">金豆</div>
                <div class="assetsValue">
                  {{ walletInfo.amount + walletInfo.income || 0 }}
                </div>
              </div>
            </div>
            <div class="assetsLine"></div>
            <div class="assetsItem" @click="showCouponNum">
              <svg-icon class="couponSvg" iconClass="couponSvg" />
              <div class="assetsInfo">
                <div class="assetsTitle">观影券</div>
                <div class="assetsValue">
                  {{ (couponNum || 0) | watchCount }}
                </div>
              </div>
            </div>
          </div>
          <div class="optionsBox">
            <div class="optItem" v-for="item in optList" :key="item.icon" @click="jumpPage(item.path)">
              <svg-icon class="optIcon" :iconClass="item.icon" />
              <div class="optName">{{ item.name }}</div>
            </div>
          </div>


          <div class="cellBox">
            <HeaderAdv :advType="advType"></HeaderAdv>

            <router-link tag="div" to="/expensesRecord" class="rowItem">
              <svg-icon class="rowIcon" iconClass="expensesRecord" />
              <div class="rowName">消费记录</div>
              <svg-icon class="rightArrow" iconClass="mineRightArrow" />
            </router-link>
            <router-link tag="div" to="/revenueCenter" class="rowItem">
              <svg-icon class="rowIcon" iconClass="revenueCenter" />
              <div class="rowName">收益中心</div>
              <svg-icon class="rightArrow" iconClass="mineRightArrow" />
            </router-link>
            <!-- <router-link tag="div" to="/appCenter" class="rowItem">
              <svg-icon class="rowIcon" iconClass="applicationCenter" />
              <div class="rowName">应用中心</div>
              <svg-icon class="rightArrow" iconClass="mineRightArrow" />
            </router-link> -->
            <router-link tag="div" to="/myMsg" class="rowItem">
              <svg-icon class="rowIcon" iconClass="myMsessage" />
              <div class="rowName msgTip">
                我的消息
                <div class="redDot" v-if="
                  this.msgHasNew.comment ||
                  this.msgHasNew.official ||
                  this.msgHasNew.reward ||
                  this.msgHasNew.thumbs
                "></div>
              </div>
              <svg-icon class="rightArrow" iconClass="mineRightArrow" />
            </router-link>
            <router-link tag="div" to="/businessCooperation" class="rowItem">
              <svg-icon class="rowIcon" iconClass="busCooperation" />
              <div class="rowName">商务合作</div>
              <svg-icon class="rightArrow" iconClass="mineRightArrow" />
            </router-link>
            <router-link tag="div" to="/officialGround" class="rowItem">
              <svg-icon class="rowIcon" iconClass="officialCommunity" />
              <div class="rowName">官方社群</div>
              <svg-icon class="rightArrow" iconClass="mineRightArrow" />
            </router-link>
            <router-link tag="div" to="/setting" class="rowItem">
              <svg-icon class="rowIcon" iconClass="settingsCenter" />
              <div class="rowName">设置中心</div>
              <svg-icon class="rightArrow" iconClass="mineRightArrow" />
            </router-link>
            <router-link tag="div" to="/focusList" class="rowItem">
              <svg-icon class="rowIcon" iconClass="focusCenter" />
              <div class="rowName">我的关注</div>
              <svg-icon class="rightArrow" iconClass="mineRightArrow" />
            </router-link>
          </div>
          <div class="sharePromotion" @click="$router.push('sharePromotion')">
            <div class="invitationCode">
              我的邀请码 {{ userInfo.promotionCode }}
            </div>
            <div class="officialUrl mb80">
              永久官方地址 {{ userInfo.promoteURL }}
            </div>
          </div>
        </div>
      </van-pull-refresh>
    </div>

    <van-popup v-model="showSignPop" class="signPop" @close="closeSignPop">
      <div class="signBox">
        <div class="signSuc">签到成功</div>
        <div class="sginDate">已累计签到{{ signCount }}天</div>
        <div class="dateBox">
          <div class="dateItem" v-for="date in 7" :key="date">
            <div class="date" :class="{ signedBg: date <= signCount }">
              <svg-icon class="couponSignDate" iconClass="couponSignDate" v-if="date == 2 || date == 7" />
              <svg-icon class="signed" iconClass="signed" v-else-if="date <= signCount" />
              <div v-else>{{ date }}</div>
              <div class="dateCoponNum" v-if="date == 2 || date == 7">x1</div>
            </div>
            <div class="line" v-if="date != 7"></div>
          </div>
        </div>
        <div class="signContentBox">
          <div class="awardTitle">
            累计签到{{ finishCondition }}天奖励观阅券
          </div>
          <div class="awardBox">
            <svg-icon class="signAward" iconClass="signAward" />x{{
              signWatchCount
            }}
          </div>
          <div class="signBtn" @click="showSignPop = false">今日已签到</div>
          <div class="signTip">累计7天/周期 循环签到</div>
        </div>
      </div>
    </van-popup>
    <div class="aiUndress" @click="$router.push('/aiUndress')"></div>
    <!-- <van-popup v-model="certificatePop" class="certificatePop" @close="closeCertificatePop">
      <div class="certificateBox">
        <div class="certificateTitle">账号凭证</div>
        <div class="certificateContent">
          受行业限制，APP无法正常使用时需升级，未绑定手机号码会导致账号信息丢失。请及时绑定手机号码或保存账号凭证，以免VIP信息丢失，造成巨大财产损失！账号丢失的用户可在 『我的』页面-账号找回，原账号的VIP信息会转移至新账号上。
        </div>
        <div class="bindPhoneBox">
          <div class="bindPhoneText" @click="bindPhone">绑定手机号</div>
          <svg-icon class="rightAttow" iconClass="settingArrow" />
        </div>
        <div class="popLine"></div>
        <div class="saveBtn" @click="saveCredentials">保存账号凭证</div>
      </div>
    </van-popup> -->
  </div>
</template>

<script>
import { queryUserInfo, queryRights, sign } from "@/api/user";
import { PullRefresh, Toast, Progress } from "vant";
import { setSessionItem } from "@/utils/longStorage";
import ImgDecypt from "@/components/ImgDecypt";
import HeaderAdv from "@/components/HeaderAdv";
import { AdType } from "@/utils/getConfig";

export default {
  components: {
    ImgDecypt,
    HeaderAdv,
    [Progress.name]: Progress,
    [PullRefresh.name]: PullRefresh,
  },
  data() {
    return {
      advType: AdType.MINE,
      msgHasNew: {},
      currentUser: {}, // 当前用户
      userInfo: {}, // 用户信息
      backgroundImg: "", // 背景
      walletInfo: {}, // 钱包信息
      couponNum: 0, // 观阅券数量
      isLoading: false,
      showSignPop: false, // 是否显示签到弹窗
      finishCondition: 0, // 累计签到天数
      signCount: 0, // 当前签到天数
      signWatchCount: 0, // 累计签到奖励
      certificatePop:
        sessionStorage.getItem("isCertificate") == 0 ? false : true, // 账号凭证
      percentage: 0, // 打卡进度
      totalDays: 0, // 需要打卡天数
      currentSignDays: 0, // 连续打卡天数
      forgetSignDays: 0, // 忘记打卡天数
      activityImg: "",
      optList: [
        {
          name: "创作中心",
          icon: "creationCenter",
          path: "creationCenter",
        },
        {
          name: "历史记录",
          icon: "historyRecord",
          path: "historyRecord",
        },
        {
          name: "已购作品",
          icon: "purchased",
          path: "purchased",
        },
        {
          name: "我的收藏",
          icon: "collect",
          path: "collect",
        },
        {
          name: "分享推广",
          icon: "sharePromotion",
          path: "sharePromotion",
        },
        {
          name: "领取兑换",
          icon: "exchangeCode",
          path: "/exchangeCode",
        },
        {
          name: "账号凭证",
          icon: "actCredentials",
          path: "/accountCredentials",
        },
        {
          name: "在线客服",
          icon: "customerService",
          path: "customerService",
        },
      ],
    };
  },
  created() {
    this.activityImg = this.$store.getters.appConfig.activityImg;
    this.currentUser = JSON.parse(sessionStorage.getItem("userInfo"));
    this.queryUserInfo();
    this.queryBalance();
    this.queryMsgHasNew();
    this.queryRights();
  },
  methods: {
    // 刷新
    async onRefresh() {
      // this.queryUserInfo();
      // this.queryBalance();
      // this.queryRights();
      Promise.all([
        this.queryUserInfo(),
        this.queryBalance(),
        this.queryMsgHasNew(),
        this.queryRights(),
      ])
        .then((e) => {
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          Toast("请求失败清稍后再试");
        });
      // console.log(axios.all())
      // axios.all([this.queryUserInfo(), this.queryBalance(), this.queryRights()])
      // .then(axios.spread(function (acct, perms) {
      //   console.log(12312312313)
      //   this.isLoading = false
      // }))
      // this.isLoading = false
    },
    // 查询用户信息
    async queryUserInfo() {
      // let req = this.$route.query.uid ? {
      //   uid: this.$route.query.uid != this.$store.getters.userInfo.uid ? this.$route.query.uid : 0
      // } : {
      //   uid: 0
      // };
      let res = await this.$Api(queryUserInfo);
      if (res.code === 200) {
        this.userInfo = res.data;
        this.totalDays =
          this.userInfo?.whoringCard?.detailInfo[0]?.totalDays || 0;
        this.currentSignDays =
          this.userInfo?.whoringCard?.detailInfo[0]?.currentSignDays || 0;
        this.forgetSignDays =
          this.userInfo?.whoringCard?.detailInfo[0]?.forgetSignDays || 0;
        this.percentage = (this.currentSignDays / this.totalDays) * 100;
        this.backgroundImg = this.userInfo.background
          ? this.userInfo.background[0]
          : "";
        setSessionItem("userInfo", JSON.stringify(res.data));
        this.$store.commit("user/SET_USERINFO", res.data);
        // this.$store.dispatch("commit/SET_USERINFO", res.data);
      }
    },

    jumpPage(path) {
      if (path) {
        this.$router.push(path);
      }
    },

    // 校验有效时间
    checkTime(timer) {
      let todayTime = new Date().getTime();
      let effectiveTime = new Date(timer).getTime();
      if (timer && todayTime < effectiveTime) {
        return true;
      }
      return false;
    },

    // 处理会员时间展示
    handleVipText() {
      let str = "畅享更多会员专属特权";
      if (this.checkTime(this.userInfo.videoFreeExpiration)) {
        let dateago = new Date(this.userInfo.vipExpireDate);
        let y = dateago.getFullYear();
        let m = dateago.getMonth() + 1;
        let d = dateago.getDate();
        str = `会员到期时间 ${y + "-" + m + "-" + d}`;
      } else if (this.checkTime(this.userInfo.vipExpireDate)) {
        let dateago = new Date(this.userInfo.vipExpireDate);
        let y = dateago.getFullYear();
        let m = dateago.getMonth() + 1;
        let d = dateago.getDate();
        str = `会员到期时间 ${y + "-" + m + "-" + d}`;
      }
      return str;
    },
    // 消息红点
    async queryMsgHasNew() {
      await this.$store.dispatch("user/updateMsgHasNew");
      this.msgHasNew = this.$store.getters.msgHasNew;
    },
    // 查询钱包
    async queryBalance() {
      await this.$store.dispatch("user/getWallet");
      this.walletInfo = this.$store.getters.walletInfo;
    },
    // 查询权益
    async queryRights() {
      let res = await this.$Api(queryRights);
      if (res && res.code == 200) {
        let couponList = res.data.watchReadCoupon || [];
        this.couponNum = couponList.length;
      }
    },
    // 前往编辑资料
    toEditInfo() {
      this.$router.push("/editInfo");
    },
    toRecharge() {
      this.$router.push("/rechargePage?t=gold");
    },
    showCouponNum() {
      Toast(`您拥有${this.couponNum}张观阅券`);
    },
    sign() {
      this.$router.push("/sign");
      // try {
      //   this.$store.commit("app/SET_LOADING", true);
      //   let res = await this.$Api(sign);
      //   this.$store.commit("app/SET_LOADING", false);
      //   if (res && res.code == 200) {
      //     this.finishCondition = res.data.finishCondition;
      //     this.signCount = res.data.signCount;
      //     // this.signCount = 2;
      //     this.signWatchCount = res.data.watchCount;
      //     this.showSignPop = true;
      //   } else {
      //     Toast(res.tip || "签到失败");
      //   }
      // } catch (error) {
      //   this.$store.commit("app/SET_LOADING", false);
      //   Toast("签到失败");
      // }
    },
    closeSignPop() {
      this.finishCondition = 0;
      this.signCount = 0;
      this.signWatchCount = 0;
    },
    closeCertificatePop() {
      sessionStorage.setItem("isCertificate", "0");
    },
    bindPhone() {
      this.certificatePop = false;
      this.$router.push("/bindPhone");
    },
    saveCredentials() {
      this.certificatePop = false;
      this.$router.push("/accountCredentials");
    },
  },
};
</script>

<style lang="scss" scoped>
.minePage {
  color: #000;
  background: #f2f2f2;
  height: 100%;

  // height: 100vh;
  // overflow-y: auto;
  .mineMain {
    height: 100%;
    overflow-y: auto;
  }

  .contentBox {
    -webkit-overflow-scrolling: touch;

    .userInfoBox {
      margin-bottom: 11px;
      padding: 7px 0 14px;
      box-sizing: border-box;
      position: relative;
      width: 100%;
      background: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .homePagebtn {
        position: absolute;
        top: 0;
        right: 4px;
        width: 42px;
        height: 42px;
        background: url("../../assets/png/homePageBtn.png") no-repeat;
        background-size: 100% 100%;
      }

      .avatarBox {
        width: 92px;
        height: 92px;
        margin-bottom: 5px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        .avatarBorder {
          width: 92px;
          height: 92px;
          background: url("../../assets/png/textBorder.png") no-repeat;
          background-size: 100% 100%;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;
        }

        .avatarImg {
          width: 63px;
          height: 63px;
        }
      }

      .userName {
        font-size: 14px;
        font-weight: 900;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        justify-content: center;

        .vipSvg {
          width: 45px;
          height: 16px;
          margin-left: 5px;
        }
      }

      .userInfo {
        display: flex;
        align-items: center;
        font-size: 12px;
        margin-bottom: 18px;
        color: #999999;

        .sexIcon {
          width: 16px;
          height: 16px;
          font-weight: 500;
        }

        .smallVerticalLine {
          width: 1px;
          height: 10px;
          margin: 0 10px;
          background: #333;
        }
      }

      .vipImage {
        width: 355px;
        height: 76px;
      }

      .vipBox {
        width: 355px;
        height: 76px;
        border-radius: 8px;
        background: #fff4e7;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 17.5px 0 8px;
        box-sizing: border-box;

        .vipIcon {
          width: 63px;
          height: 58px;
        }

        .vipInfo {
          flex: 2;

          .vipTitle {
            width: 80px;
            height: 17px;
            background: url("../../assets/png/vipTitle.png");
            background-size: 100% 100%;
            margin-bottom: 11px;
          }

          .vipDate {
            color: #686875;
            font-family: "PingFang SC";
            font-size: 12.326px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }
        }

        .upLevel {
          width: 77.4px;
          height: 24.6px;
          background: url("../../assets/png/upLevel.png");
          background-size: 100% 100%;
        }
      }
    }

    .signBox {
      width: 355px;
      height: 39px;
      margin: 0 auto;
      border-radius: 10px 10px 0px 0px;
      background: linear-gradient(90deg, #f2ebdb 0%, #e4bd86 100%);
      padding: 0 13px 0 5px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .signIcon {
        width: 37px;
        height: 37px;
      }

      .signTitle {
        flex: 2;
        margin: 0 5px;

        .signTitleImg {
          background: url("../../assets/png/signTitle.png") center no-repeat;
          background-size: 100% 100%;
          width: 56px;
          height: 16px;
        }
      }

      .signBoxBtn {
        width: 68px;
        height: 23px;
        border-radius: 23px;
        border-radius: 16px;
        background: rgba(255, 255, 255, 0.8);
        display: flex;
        align-items: center;
        justify-content: center;
        color: #a37736;
        font-family: "PingFang HK";
        font-size: 12px;
        font-weight: 500;
      }
    }

    .assetsBox {
      width: 100%;
      background: #fff;
      display: flex;
      // align-items: center;
      justify-content: space-between;

      .assetsItem {
        width: 186px;
        display: flex;
        justify-content: center;
        overflow: hidden;

        .assetsInfo {
          .assetsTitle {
            font-size: 12px;
            font-family: "PingFang HK";
            font-weight: 500;
            margin-top: 15px;
          }

          .assetsValue {
            color: #999;
            font-family: "PingFang HK";
            font-size: 12px;
            font-weight: 300;
          }
        }

        .goldSvg {
          width: 36px;
          height: 36px;
          margin-top: 12px;
          margin-right: 19px;
        }

        .couponSvg {
          width: 36px;
          height: 36px;
          margin-top: 12px;
          margin-right: 19px;
        }
      }

      .assetsLine {
        width: 2px;
        height: 38px;
        margin-top: 15px;
        border-radius: 5px;
        background: rgba(217, 217, 217, 0.5);
      }
    }

    .optionsBox {
      padding: 20px 41px 20px;
      box-sizing: border-box;
      background: #fff;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-row-gap: 20px;
      grid-column-gap: 44px;

      .optItem {
        display: flex;
        flex-direction: column;
        align-items: center;

        .optIcon {
          width: 30px;
          height: 30px;
          margin-bottom: 6px;
        }

        .optName {
          font-size: 10px;
        }
      }
    }

    .cellBox {
      background: #fff;
      overflow: hidden;
      padding: 10px 18px 26px 10px;
      box-sizing: border-box;
      margin-bottom: 18px;
      margin: 10px 0 13px;

      .rowItem {
        width: 100%;
        height: 30px;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #fff;

        .rowIcon {
          width: 30px;
          height: 30px;
          margin-right: 20px;
        }

        .rowName {
          flex: 2;
          font-size: 12px;
        }

        .rightArrow {
          width: 12px;
          height: 12px;
        }
      }

      .rowItem:last-child {
        margin-bottom: 0;
      }
    }

    .sharePromotion {
      .invitationCode {
        color: #666;
        font-family: "PingFang SC";
        font-size: 16px;
        font-weight: 500;
        text-align: center;
        margin-bottom: 6px;
      }

      .officialUrl {
        color: #999;
        font-family: "PingFang SC";
        font-size: 12px;
        font-weight: 400;
        text-align: center;
      }
    }

    .mb80 {
      margin-bottom: 150px;
    }
  }

  .signPop {
    background: #00000000;

    .signBox {
      width: 265px;
      height: 337px;
      background: url("../../assets/png/signPop.png") center no-repeat;
      background-size: 100% 100%;
      position: relative;

      .signSuc {
        font-size: 24px;
        color: #fff;
        position: absolute;
        top: 31px;
        left: 29px;
      }

      .sginDate {
        width: 96px;
        height: 18px;
        border-radius: 18px;
        line-height: 18px;
        font-size: 10px;
        text-align: center;
        background: #fbcf2f;
        position: absolute;
        top: 67px;
        left: 29px;
      }

      .dateBox {
        position: absolute;
        top: 146px;
        left: 50%;
        width: 210px;
        transform: translateX(-50%);
        display: flex;
        align-items: center;
        justify-content: space-between;

        .dateItem {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .signedBg {
            background: #9521ff !important;
          }

          .date {
            width: 18px;
            height: 18px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #cdbbf5;
            border-radius: 50%;
            color: #fff;
            position: relative;

            .couponSignDate {
              width: 14.5px;
              height: 13.4px;
            }

            .signed {
              width: 18px;
              height: 18px;
            }

            .dateCoponNum {
              position: absolute;
              bottom: -14px;
              font-size: 10px;
              color: #7145e7;
            }
          }

          .line {
            width: 6px;
            height: 2px;
            background: #cdbbf5;
            margin: 0 4px;
          }
        }
      }

      .signContentBox {
        position: absolute;
        top: 189px;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
      }

      .awardTitle {
        font-size: 15px;
        white-space: nowrap;
      }

      .awardBox {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #9521f9;
        font-size: 24px;

        .signAward {
          width: 52px;
          height: 52px;
        }
      }

      .signBtn {
        width: 218px;
        height: 39px;
        line-height: 39px;
        border-radius: 39px;
        font-size: 18px;
        color: #fff;
        background: #7145e7;
        text-align: center;
      }

      .signTip {
        color: #999999;
        font-size: 10px;
        margin-top: 8px;
      }
    }
  }

  .certificatePop {
    border-radius: 9px;

    .certificateBox {
      width: 307px;

      // padding: 0 18px;
      // box-sizing: border-box;
      .certificateTitle {
        text-align: center;
        font-size: 18px;
        font-weight: 600;
        margin: 18px 0;
      }

      .certificateContent {
        font-size: 12px;
        line-height: 24px;
        margin: 0 18px 18px;
      }

      .bindPhoneBox {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 13px;

        .bindPhoneText {
          font-size: 15px;
          color: #999999;
        }
      }

      .popLine {
        width: 100%;
        height: 1px;
        background: #e6e6e6;
      }

      .saveBtn {
        padding: 14px 0 24px;
        box-sizing: border-box;
        font-size: 18px;
        text-align: center;
      }
    }
  }

  .aiUndress {
    height: 70px;
    width: 70px;
    background: url("./../../assets/png/aiUndress.png") no-repeat;
    background-size: 100% 100%;
    position: fixed;
    right: 15px;
    bottom: 90px;
    z-index: 999;
  }
}

.msgTip {
  position: relative;

  .redDot {
    position: absolute;
    top: -8px;
    right: -20px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #fc4162;
  }
}

.signIn {
  margin: 16px 0;
  width: 100%;
  height: 32px;
  font-size: 12px;
  font-weight: 500;

  .signIn_top {
    padding-bottom: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .signIn_left {
      >span {
        color: rgba(33, 33, 33, 0.6);
      }
    }

    .signIn_right {
      color: rgba(33, 33, 33, 0.6);
    }

    .ckeckBtn {
      color: #fc4162;
    }
  }

  .signIn_bottom {
    width: 100%;
    height: 15px;

    .progress {
      width: 100%;
      height: 10px;
      border-radius: 20px;
      background: #dcdcdc !important;
    }
  }
}
</style>
