<template>
  <div class="headerAdv">
    <!-- 5宫格广告 -->
    <div class="fiveGrid" v-if="advList.length">
      <div class="rectAdvBox" @click="jumpAdv(item)" v-for="item in advList" :key="item.id">
        <ImgDecypt class="rectAdvIcon" :src="item.cover" />
        <div class="rectName">{{ item.title }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import ImgDecypt from "@/components/ImgDecypt";
import { getAdItem } from '@/utils/getConfig';
import { jumpAdv } from '@/utils/getConfig';

export default {
  components: { ImgDecypt },
  props: {
    advType: {
      type: Number,
      default: 28
    }
  },
  computed: {
    advList() {
      const list = (getAdItem(this.advType) || []).slice(0, 10)
      return list
    }
  },
  methods: {
    jumpAdv
  }
}
</script>
<style scoped lang="scss">
.headerAdv {
  .fiveGrid {
    margin: 4px 0 12px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-row-gap: 14px;
    grid-column-gap: 10px;
    padding: 0 12px;
    box-sizing: border-box;
    .rectAdvBox {
      width: 60px;
      .rectAdvIcon {
        height: 60px;
        width: 60px;
        border-radius: 14.6px;
        overflow: hidden;
      }
      .rectName {
        font-size: 12px;
        color: #666;
        margin-top: 4px;
        overflow: hidden;
        white-space: nowrap;
        text-align: center;
      }
    }
  }
}
</style>
